import React from "react";

import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <div>
      <SEO title="404: Not found" />
      <div>
        <h2 className="text-gray-900 text-2xl font-bold inline-block my-8 p-3">
          Page not found
        </h2>
      </div>
    </div>
  );
}

export default NotFoundPage;
